/* src/components/PrivacyPolicy.css */
.privacy-policy-container {
    /* max-width: 900px; */
    margin-top: 3rem;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color:aliceblue;
  }
  
  .privacy-policy-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container h2,
  .privacy-policy-container h3 {
    color: rgb(254, 200, 0, 0.9);
    margin-top: 20px;
  }
  
  .privacy-policy-container p {
    font-size: 16px;
    margin-bottom: 12px;
  }
  
  .privacy-policy-container ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .privacy-policy-container li {
    margin-bottom: 8px;
  }
  
  .privacy-policy-container a {
    color: rgb(254, 200, 0, 0.9);
    text-decoration: none;
  }
  
  .privacy-policy-container a:hover {
    text-decoration: underline;
  }
  